import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import Link from '../../components/Link';
import {
  Container,
  Podium,
  TopThree,
  Name,
  ProdLink,
  TopThreeDetail,
  TopThreeContainer,
  OtherImage,
  ListItem,
  List,
  Rank,
  Box,
} from './StyledTopRated';
import ImgPodium from '../../assets/images/topten/podium.png';
import Product from '../../components/Product/Product';
import PageTitle from '../../components/PageTitle/PageTitle';

const TopRated = ({ data }) => {
  const { t } = useTranslation();
  const { isMobileView } = useSelector((state) => state.common);
  let topTen = data.allProduct.nodes;

  const ranking = [
    123748,
    122319,
    122321,
    122383,
    122738,
    122330,
    122387,
    123775,
    122393,
    122331,
  ];

  for (const [index, rank] of ranking.entries()) {
    for (const item of topTen) {
      if (rank === item.ref_id) {
        item.rank = index;
      }
    }
  }

  const orderRanking = _.orderBy(topTen, ['rank'], ['asc']);
  const path = '/product';

  return (
    <Layout hasBreadcrumb={true} fullwidth={isMobileView}>
      <Container>
        {!isMobileView ? (
          <>
            <TopThreeContainer>
              <TopThree>
                <Podium>
                  <img src={ImgPodium} alt="podium" />
                </Podium>
                {[0, 1, 2].map((item) => {
                  return (
                    <div
                      className={`prod num${item + 1}`}
                      key={orderRanking[item].id}
                    >
                      <Link to={`${path}/${orderRanking[item].slug}`}>
                        <Img
                          fluid={
                            orderRanking[item].remoteImage.childImageSharp.fluid
                          }
                        />
                      </Link>
                    </div>
                  );
                })}
              </TopThree>
              <TopThreeDetail className="row no-gutters">
                {[1, 0, 2].map((item, index) => {
                  return (
                    <div className="col-4 text-center" key={index}>
                      <Name>{orderRanking[item].content.name}</Name>
                      <ProdLink to={`${path}/${orderRanking[item].slug}`}>
                        {t('learn_more')}
                      </ProdLink>
                    </div>
                  );
                })}
              </TopThreeDetail>
            </TopThreeContainer>
            <List>
              {orderRanking.slice(3).map((item) => {
                return (
                  <ListItem
                    className="row no-gutters align-items-start"
                    key={item.id}
                  >
                    <Rank className="align-self-center" />
                    <OtherImage>
                      <Img fluid={item.remoteImage.childImageSharp.fluid} />
                    </OtherImage>
                    <Name>{item.content.name}</Name>
                    <ProdLink
                      className="align-self-end"
                      to={`${path}/${item.slug}`}
                    >
                      {t('learn_more')}
                    </ProdLink>
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <>
            <PageTitle>{t('top_rated')}</PageTitle>
            <Box className="row no-gutters">
              {orderRanking.map((item) => {
                return (
                  <Product
                    key={item.id}
                    extraContent={<Rank />}
                    classes={{ container: 'col-sm-6' }}
                    title={item.content.name}
                    image={item.remoteImage.childImageSharp.fluid}
                    path={`${path}/${item.slug}`}
                  />
                );
              })}
            </Box>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default TopRated;

export const pageQuery = graphql`
  query($locale: String) {
    allProduct(
      filter: {
        genders: { elemMatch: { slug: { eq: "men" } } }
        ref_id: {
          in: [
            123748
            122319
            122321
            122383
            122738
            122330
            122387
            123775
            122393
            122331
          ]
        }
      }
    ) {
      nodes {
        id
        ref_id
        content(locale: $locale) {
          name
        }
        slug
        remoteImage {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
